<template>
  <v-card>
    <v-card-title><h4>Applications</h4></v-card-title>
    <v-data-table
      :headers="headers"
      :items="items.items"
      :server-items-length="items.meta.total"
      :loading="loading"
      :options.sync="options"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-spacer></v-spacer>
          <v-dialog v-model="dialog" max-width="500px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
                <v-icon dense small>{{ icons.mdiPlus }}</v-icon
                >&nbsp;New Application
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="text-h5">{{ formTitle }}</span>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <v-form ref="edit">
                    <v-row>
                      <v-col cols="12">
                        <v-text-field
                          label="Name"
                          required
                          v-model="editedItem.name"
                          :error-messages="nameErrors"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-text-field
                          label="Description"
                          required
                          v-model="editedItem.description"
                          :error-messages="descriptionErrors"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-text-field
                          label="URL"
                          type="url"
                          placeholder="https://appName.psychomeasure.org"
                          required
                          v-model="editedItem.url"
                          :error-messages="urlErrors"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" @click="save"> Save </v-btn>
                <v-btn color="secondary" outlined @click="close">
                  Cancel
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogDelete" max-width="400px">
            <v-card>
              <v-card-title class="text-h5 text-break text-center"
                >Are you sure you want to delete this item?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="secondary" outlined @click="closeDelete"
                  >Cancel</v-btn
                >
                <v-btn color="primary" @click="deleteItemConfirm">OK</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.url="{ item }">
        <a :href="item.url">{{ item.url }}</a>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" @click="editItem(item)">
          {{ icons.mdiPencil }}
        </v-icon>
        <v-icon small @click="deleteItem(item)"> {{ icons.mdiDelete }} </v-icon>
      </template>
      <template v-slot:no-data>
        <v-btn color="primary" @click="getDataFromApi"> Reset </v-btn>
      </template>
    </v-data-table>
  </v-card>
</template>
<script>
import { mdiPencil, mdiPlus, mdiDelete } from "@mdi/js"
import { mapActions, mapState } from "vuex"
import { validationMixin } from "vuelidate"
import { required, url } from "vuelidate/lib/validators"

export default {
  mixins: [validationMixin],
  validations() {
    if (this.editedIndex == -1) {
      return {
        editedItem: {
          name: { required },
          description: { required },
          url: { required, url },
        },
      }
    } else {
      return {
        editedItem: {
          name: { required },
          description: { required },
          url: { required, url },
        },
      }
    }
  },
  data() {
    return {
      loading: true,
      total: 0,
      options: {},

      dialog: false,
      dialogDelete: false,

      editedIndex: -1,
      editedItem: {
        id: -1,
        description: "",
        url: "",
      },
      defaultItem: {
        id: -1,
        description: "",
        url: "",
      },
      icons: { mdiPencil, mdiPlus, mdiDelete },
    }
  },
  computed: {
    headers() {
      return [
        { text: "Name", value: "name" },
        { text: "Description", sortable: false, value: "description" },
        { text: "URL", sortable: false, value: "url" },
        { text: "Actions", sortable: false, value: "actions", align: "center" },
      ]
    },
    formTitle() {
      return this.editedIndex === -1 ? "New Application" : "Edit Application"
    },
    nameErrors() {
      const errors = []
      if (!this.$v.editedItem.name.$dirty) return errors
      !this.$v.editedItem.name.required && errors.push("Name is required.")
      return errors
    },
    descriptionErrors() {
      const errors = []
      if (!this.$v.editedItem.description.$dirty) return errors
      !this.$v.editedItem.description.required &&
        errors.push("Description is required.")
      return errors
    },
    urlErrors() {
      const errors = []
      if (!this.$v.editedItem.url.$dirty) return errors
      !this.$v.editedItem.url.required && errors.push("URL is required.")
      !this.$v.editedItem.url.url && errors.push("Must be a valid URL")

      return errors
    },
    ...mapState({
      items: state => state.applications.applications,
    }),
  },
  watch: {
    options: {
      handler() {
        this.getDataFromApi()
      },
      deep: true,
    },
    dialog(val) {
      val || this.close()
    },
    dialogDelete(val) {
      val || this.closeDelete()
    },
  },
  methods: {
    getDataFromApi() {
      this.loading = true
      this.$nextTick(() => {
        const start = (this.options.page - 1) * this.options.itemsPerPage
        this.loadApplications({
          start: start,
          limit: this.options.itemsPerPage,
        }).then(() => {
          this.loading = false
        })
      })
    },

    editItem(item) {
      this.editedIndex = this.items.items.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },

    deleteItem(item) {
      this.editedIndex = this.items.items.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },

    deleteItemConfirm() {
      this.loading = true
      this.deleteApplication(this.editedItem.id).then(() => {
        this.loading = false
      })
      this.closeDelete()
    },

    close() {
      this.dialog = false
      this.$v.$reset()
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    save() {
      this.$v.$touch()
      if (!this.$v.$anyError) {
        this.loading = true
        if (this.editedIndex > -1) {
          this.editApplication(this.editedItem).then(() => {
            this.getDataFromApi()
          })
        } else {
          this.addApplication(this.editedItem).then(() => {
            this.getDataFromApi()
          })
        }
        this.close()
      }
    },
    ...mapActions("applications", [
      "loadApplications",
      "addApplication",
      "editApplication",
      "deleteApplication",
    ]),
  },
  mounted() {},
}
</script>
